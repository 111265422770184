var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"hide-footer":"","no-close-on-backdrop":"","hide-header":"","no-fade":"","size":"lg"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row p-5"},[_c('div',{staticClass:"col p-0 welcome ml-4 mt-4 align-start"},[(_vm.action === 'delete')?_c('img',{attrs:{"src":require("@/assets/group-22.svg"),"alt":""}}):_c('img',{staticClass:"welcome--image",attrs:{"src":require("@/assets/group-21.png"),"alt":""}})]),_c('div',{staticClass:"col px-5"},[_c('p',{staticClass:"title"},[(_vm.action === 'delete')?_c('span',[_vm._v("Delete plan?")]):(_vm.action === 'add')?_c('span',[_vm._v("Plan details")]):(_vm.action === 'share')?_c('span',[_vm._v("Share plan details")]):_vm._e()]),(_vm.action === 'delete')?_c('p',{staticClass:"subtitle"},[_vm._v(" Hey Aditya, are you sure to delete the plan from the system? ")]):_c('div',[_c('b-form-input',{staticClass:"input-wrapper mb-5",model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"w-100"},[_c('w-dropdown',{attrs:{"options":_vm.action === 'add' ? _vm.planList : _vm.userList,"placeHolder":'Select',"labelText":_vm.action === 'add' ? 'Plan saved in' : 'Share plan to'},on:{"input":function($event){return _vm.selectDropdown($event)}}})],1)],1),_c('div',{staticClass:"d-flex w-100 m-0",class:[
              _vm.action === 'delete'
                ? 'justify-content-start'
                : 'justify-content-end',
            ]},[_c('button',{staticClass:"btn-effect",class:{
                'bg-red': _vm.action === 'delete',
                'btn-effect-disabled': _vm.disabled,
              },on:{"click":_vm.handleSave}},[(_vm.action === 'delete')?_c('span',{staticClass:"btn-text"},[_vm._v("Yes, delete")]):_c('span',{staticClass:"btn-text"},[_vm._v("Save Plan")]),_c('i',{staticClass:"fas fa-arrow-right ml-5 pl-4 d-flex",staticStyle:{"align-items":"center"}})])]),(_vm.action === 'delete')?_c('div',{staticClass:"change-mind",on:{"click":_vm.close}},[_vm._v(" No, I changed my mind ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }